<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Dados</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-select
            id="list"
            v-model="querySearch"
            :options="statusOptions"
            class="w-75"
          >
            <template #first />
          </b-form-select>
        </b-col>

        <!-- Search -->
        <!-- <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Pesquisar..."
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="invoice-filter-select"
              placeholder="Selecione o Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col> -->
      </b-row>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="orders"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link
          :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(invoiceStatus)="data">
        <b-avatar
          :id="`invoice-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).variant}`"
        >
          <feather-icon
            :icon="resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`invoice-row-${data.item.id}`"
          placement="top"
        >
          <p class="mb-0">
            {{ data.item.invoiceStatus }}
          </p>
          <p class="mb-0">
            Balance: {{ data.item.balance }}
          </p>
          <p class="mb-0">
            Due Date: {{ data.item.dueDate }}
          </p>
        </b-tooltip>
      </template>

      <!-- Column: Client -->
      <template #cell(client)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.client.name)"
              :variant="`light-${resolveClientAvatarVariant(data.item.invoiceStatus)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.client.name }}
          </span>
          <small class="text-muted">{{ data.item.client.companyEmail }}</small>
        </b-media>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Balance -->
      <template #cell(balance)="data">
        <template v-if="data.value === 0">
          <b-badge
            pill
            variant="light-success"
          >
            Paid
          </b-badge>
        </template>
        <template v-else>
          {{ data.value }}
        </template>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-send-icon`"
            icon="SendIcon"
            class="cursor-pointer"
            size="16"
          />
          <b-tooltip
            title="Send Invoice"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-send-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'apps-invoice-preview', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Download</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'apps-invoice-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Duplicate</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} dados</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="orders.length"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useInvoicesList from './useInvoiceList'

import invoiceStoreModule from '../invoiceStoreModule'
import Vue from 'vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  setup() {
    const statusOptions = [
      {
        value: '',
        text: 'Selecione o filtro'
      },
      {
        value: 'stt-1',
        text: 'Aguardando pag.'
      },
      {
        value: 'stt-2',
        text: 'Em análise'
      },
      {
        value: 'stt-3',
        text: 'Pago'
      },
      {
        value: 'stt-4',
        text: 'Disponível'
      },
      {
        value: 'stt-5',
        text: 'Em disputa'
      },
      {
        value: 'stt-6',
        text: 'Devolvida'
      },
      {
        value: 'stt-7',
        text: 'Cancelada',
      },
      {
        value: 'stt-8',
        text: 'Retenção temporária'
      },
      {
        value: 'mp-9',
        text: 'Cartão'
      },
      {
        value: 'mp-10',
        text: 'Boleto'
      },
      {
        value: 'mp-11',
        text: 'Débito'
      },
      {
        value: 'mp-12',
        text: 'Gŕatis'
      },
    ]

    const {
      fetchInvoices,
      totalInvoices,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
      fetchInvoices,
      totalInvoices,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
  data() {
    return {
      querySearch: '',
      orders: [],
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
      tableColumns: [
        {
          key: 'id',
          label: 'id',
          sortable: true
        },
        {
          key: 'payment_type',
          label: 'Meio de pagamento',
          sortable: true
        },
        {
          key: 'transaction_status',
          label: 'Status transação',
          sortable: true
        },
        {
          key: 'amount',
          label: 'Valor',
          sortable: true,
        },
      ]
    }
  },
  computed: {
    statistics() {
      return this.$store.state.congressAdministrator.statistics
    },
  },
  watch: {
    statistics() {
      this.orders = []
      this.listDefaultOrders(this.statistics.orders)
    },
    querySearch() {
      if (this.querySearch !== '') {
        // get the index
        const index = this.querySearch.split('-')[1]
        // get object index
        const object = this.statusOptions[index]
        const type = object.value.split('-')[0]
        const { text } = object
        this.search(type, text)
      } else {
        this.orders = []
        this.listDefaultOrders(this.statistics.orders)
      }
      // add an if statement to regulate arrays if null/'' show all results
    },
  },
  mounted() {},
  methods: {
    search(type, value) {
      this.orders = []
      this.statistics.orders.forEach(element => {
        if (type === 'stt' && element.transaction_status === value) {
          this.orders.push(element)
        } else if (type === 'mp' && element.payment_type === value) {
          this.orders.push(element)
        }
      })
    },
    listDefaultOrders(arr) {
      arr.forEach(item => {
        // Tipo de pagamento
        if (item.payment_type === 1) {
          item.payment_type = 'Cartão'
        } else if (item.payment_type === 2) {
          item.payment_type = 'Boleto'
        } else if (item.payment_type === 3) {
          item.payment_type = 'Débito'
        } else {
          item.payment_type = 'Gŕatis'
        }

        // Status da transação
        if (item.transaction_status === 1) {
          item.transaction_status = 'Aguardando pag.'
        } else if (item.transaction_status === 2) {
          item.transaction_status = 'Em análise'
        } else if (item.transaction_status === 3) {
          item.transaction_status = 'Pago'
        } else if (item.transaction_status === 4) {
          item.transaction_status = 'Disponível'
        } else if (item.transaction_status === 5) {
          item.transaction_status = 'Em disputa'
        } else if (item.transaction_status === 6) {
          item.transaction_status = 'Devolvida'
        } else if (item.transaction_status === 7) {
          item.transaction_status = 'Cancelada'
        } else if (item.transaction_status === 8) {
          item.transaction_status = 'Retenção temporária'
        }

        this.orders.push(item)
      })
    }
  },
}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
